import React from 'react';
import {
    Flex,
    Box,
    Button,
    Select,
    Text,
    Slider,
    SliderTrack,
    SliderFilledTrack,
    SliderThumb,
    useDisclosure,
    Modal,
    ModalContent,
    ModalBody,
    ModalHeader,
    Image,
    ModalOverlay,
    Link
} from '@chakra-ui/react';
import { FiChevronDown } from 'react-icons/fi';
import dayjs from 'dayjs';

const Sidebar = ({ 
    loading, 
    actorsLoading,
    handleSubmit, 
    countries,
    country, 
    handleChangeCountry,
    date,
    day, 
    setDay, 
    forecastData, 
    actors,
    actor, 
    setActor 
}) => {
    return (
        <Flex py="1.5em" pl={"0.5em"} pr="1.5em" flexDirection="column" justifyContent="center" width="100%">
            <CountrySelector
                loading={loading}
                actorsLoading={actorsLoading}
                handleSubmit={handleSubmit}
                countries={countries}
                country={country}
                handleChangeCountry={handleChangeCountry}
                actors={actors}
                actor={actor}
                setActor={setActor}
                date={date}
            />
            <TimeScaleSlider date={date} day={day} setDay={setDay} />
            <ForecastDetails forecastData={forecastData} country={country} actors={actors} />
            <HeatMapLegend />
            <ForecastCredits />
        </Flex>
    );
};

const HeatMapLegend = () => {
    return (
        <Flex
            flexDir="column"
            background="gray.50"
            width="100%"
            py={"1em"}
            px={"1.5em"}
            mb={"1em"}
            borderRadius="10px"
            justifyContent="center"
        >
            <Text fontWeight={"600"} fontSize={"14px"} mb={"1em"}>
                Conflict Probability
            </Text>
            <Box
                height="12.5px"
                width="100%"
                background="linear-gradient(to right, rgb(255, 255, 224), rgb(255, 220, 0), orange, red)"
                border="0.25px solid #ccc"
                borderRadius={30}
                mb={"0.5em"}
            />
            <Flex justifyContent="space-between" width="100%" fontSize="10px" color="gray.600">
                <Text>0%</Text>
                <Text>20%</Text>
                <Text>40%</Text>
                <Text>60%</Text>
                <Text>80%</Text>
                <Text>100%</Text>
            </Flex>
            <Text fontWeight="400" mt="1.5em" color="grey" fontSize="10px">
            Types of Conflict: air/drone strike, armed clash, shelling/artillery/missile attack, disrupted weapons use, remote explosive/landmine/IED, violent demonstration, looting/property destruction, abduction/forced disappearance, territory seized, mob violence, or other physical attacks.
            </Text>
        </Flex>
    );
};

const CountrySelector = ({ loading, actorsLoading, handleSubmit, countries, country, handleChangeCountry, actors, actor, setActor, date }) => {
    return (
        <Flex
            flexDir="column"
            background="gray.50"
            pt={"1em"}
            pb={"1.5em"}
            px={"1.5em"}
            mb={"1em"}
            borderRadius="10px"
            justifyContent="center"
            width="100%"
        >
            <Text fontWeight="600" fontSize="14px" mb={"1em"}>Country of Conflict</Text>
            <Flex flexDir="column" width="100%">
                <Select
                    value={country}
                    onChange={(e) => handleChangeCountry(e.target.value)}
                    placeholder="Select a country"
                    bg="gray.100"
                    fontSize="12px"
                    h="30px"
                    width="100%"
                    mb={"0.5em"}
                    borderRadius={2.5}
                    icon={<FiChevronDown />}
                    iconSize="15px"
                    focusBorderColor="transparent"
                    isDisabled={!date}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    {countries.map((country, index) => (
                        <option key={index} value={country}>
                            {country}
                        </option>
                    ))}
                </Select>
                <Select
                    value={actor}
                    onChange={(e) => setActor(e.target.value)}
                    placeholder="Select an actor"
                    bg="gray.100"
                    fontSize="12px"
                    h="30px"
                    width="100%"
                    mb={"1em"}
                    borderRadius={2.5}
                    icon={<FiChevronDown />}
                    iconSize="15px"
                    focusBorderColor="transparent"
                    isDisabled={!country || actorsLoading}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    {actors.map((actor, index) => (
                        <option key={index} value={actor}>
                            {actor === "conflict_events" ? "All Actors" : actor}
                        </option>
                    ))}
                </Select>
                <Button
                    backgroundColor="red.500"
                    color="white"
                    h="30px"
                    width="100%"
                    fontSize="12px"
                    borderRadius={2.5}
                    onClick={handleSubmit}
                    _hover={{ bg: 'red.600' }}
                    isDisabled={!actor || actorsLoading}
                    isLoading={loading}
                >
                    Submit
                </Button>
            </Flex>
        </Flex>
    );
};

const TimeScaleSlider = ({ date, day, setDay }) => {
    
    const startDate = dayjs(date);
    const endDate = startDate.add(6, 'day');
    const dateArray = Array.from({ length: 7 }, (_, i) => startDate.add(i, 'day').format('MM/DD'));

    return (
        <Box
            flexDir="column"
            background="gray.50"
            py={"1em"}
            px={"1.5em"}
            mb={"1em"}
            borderRadius="10px"
            justifyContent="center"
            width="100%"
        >
            <Text fontWeight="600" fontSize="14px" mb={"0.75em"}>
                Forecast for {startDate.format('MMMM D')} – {endDate.format('MMMM D')}
            </Text>
            <Slider
                min={0}
                max={6}
                step={1}
                value={day}
                onChange={(val) => setDay(val)}
                colorScheme="red"
                ml="0.5em"
                width="95%"
            >
                <SliderTrack bg="gray.300">
                    <SliderFilledTrack />
                </SliderTrack>
                <SliderThumb boxSize={3} bg="red.500" />
            </Slider>

            <Flex
                width="99%"
                justifyContent="space-between"
                alignItems="center"
                fontSize="10px"
            >
                {dateArray.map((date, index) => (
                    <Box key={index}>
                        <Text fontWeight={day === index ? "bold" : "normal"} color={day === index ? 'red.500' : 'gray.600'}>
                            {date}
                        </Text>
                    </Box>
                ))}
            </Flex>
            <Text fontWeight="400" mt="1.5em" color="grey" fontSize="10px">
                Note: Forecast data updates weekly on Wednesdays at 1am EST
            </Text>
        </Box>
    );
};

const ForecastDetails = ({ forecastData }) => {
    // const { isOpen: isExplanationOpen, onOpen: onExplanationOpen, onClose: onExplanationClose } = useDisclosure();
    const { isOpen: isPerformanceOpen, onOpen: onPerformanceOpen, onClose: onPerformanceClose } = useDisclosure();

    return (
        <Flex
            flexDir="column"
            background="gray.50"
            px={"1.5em"}
            py={"1em"}
            mb={"1em"}
            borderRadius="10px"
            justifyContent="center"
            width="100%"
        >
            <Text fontWeight="600" fontSize="14px" mb={"1em"}>Forecast Details</Text>
            {/* <Button
                backgroundColor="red.500"
                color="white"
                h="30px"
                width="100%"
                fontSize="12px"
                borderRadius={2.5}
                _hover={{ bg: 'red.600' }}
                mb={"0.5em"}
                isDisabled={!forecastData}
                cursor="pointer"
                onClick={onExplanationOpen}
            >
                Model Explanation
            </Button>
            <ModelExplanationModal
                isOpen={isExplanationOpen}
                onClose={onExplanationClose}
                image={forecastData?.feature_importance_plot}
            /> */}
            <Button
                backgroundColor="red.500"
                color="white"
                h="30px"
                width="100%"
                fontSize="12px"
                borderRadius={2.5}
                _hover={{ bg: 'red.600' }}
                mb={"0.5em"}
                isDisabled={!forecastData}
                cursor="pointer"
                onClick={onPerformanceOpen}
            >
                Model Performance
            </Button>
            <ModelPerformanceModal
                isOpen={isPerformanceOpen}
                onClose={onPerformanceClose}
                image={forecastData?.confusion_matrix}
            />
        </Flex>
    );
}

// const ModelExplanationModal = ({ isOpen, onClose, image }) => {
//     return (
//       <Modal isOpen={isOpen} onClose={onClose} isCentered>
//         <ModalOverlay />
//         <ModalContent
//           borderRadius="lg"
//           boxShadow="xl" // Enhanced shadow for depth
//           width="70vw"
//           maxWidth="800px" // Set a smaller max width for compact design
//           height="auto" // Let content dictate height
//           pb="1.5em"
//           pt="0.5em"
//           px="2em"
//         >
//           {/* Header */}
//           <ModalHeader
//             fontSize="32px" // Same styling as the ModelPerformanceModal
//             fontWeight="700"
//             textAlign="center"
//             color="red.500"
//             paddingBottom="0"
//           >
//             Shap Value Model
//           </ModalHeader>
  
//           <ModalBody display="flex" flexDirection="column" justifyContent="center" pb="1em">
//             {/* Explanation Text */}
//             <Text
//               textAlign="center"
//               fontSize="15px"
//             //   fontWeight="300"
//               color="gray.600"
//               mb="1em"
//             >
//               Comparison of how much each of the listed variables contributed to the conflict probability
//             </Text>
  
//             {/* Image Section */}
//             <Box
//               borderRadius="lg"
//               borderWidth="1px"
//               borderColor="gray.200"
//               height="auto"
//               display="flex"
//               alignItems="center"
//               justifyContent="center"
//               padding="1em"
//               backgroundColor="white" // Clean white background
//             >
//               <Image
//                 src={image}
//                 alt="Shap Value Model"
//                 objectFit="contain"
//                 maxWidth="100%"
//                 maxHeight="550px" // Limit image height for better layout
//               />
//             </Box>
//           </ModalBody>
//         </ModalContent>
//       </Modal>
//     );
// };

const ModelPerformanceModal = ({ isOpen, onClose, image }) => {
    return (
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent
          borderRadius="lg"
          boxShadow="xl" // Enhanced shadow for depth
          width={{ base: '90vw', md: '70vw' }} // Adjust width for small and large screens
          maxWidth="800px" // Set a smaller max width for compact design
          height="auto" // Let content dictate height
          pb="1.5em"
          pt="0.5em"
          px={{ base: '1.5em', md: '2em' }} // Add responsive padding for smaller screens
        >
          {/* Header */}
          <ModalHeader
            fontSize={{ base: '24px', md: '32px' }} // Make header font smaller on small screens
            fontWeight="700"
            textAlign="center"
            color="red.500"
            paddingBottom="1"
          >
            Model Performance
          </ModalHeader>
          
          <ModalBody display="flex" flexDirection="column" justifyContent="center" pb="1em">
            {/* Bullet Point List */}
            <Box
              textAlign="left"
              fontSize={{ base: '12px', md: '15px' }} // Make font size responsive
              color="gray.600"
              mb="1.5em"
              lineHeight="1.7" // Consistent line height for better readability
            >
              <ul style={{ listStyleType: 'disc', marginLeft: '20px' }}>
                <li><b>Top-Left Box</b>: Correctly predicted no conflicts over the past 3 weeks</li>
                <li><b>Top-Right Box</b>: Incorrectly predicted conflicts over the past 3 weeks</li>
                <li><b>Bottom-Left Box</b>: Unpredicted conflicts over the past 3 weeks</li>
                <li><b>Bottom-Right Box</b>: Correctly predicted conflicts over the past 3 weeks</li>
              </ul>
            </Box>
  
            {/* Image Section */}
            <Box
              borderRadius="lg"
              borderWidth="1px"
              borderColor="gray.200"
              height="auto"
              display="flex"
              alignItems="center"
              justifyContent="center"
              padding="1em"
              backgroundColor="white" // Clean white background
            >
              <Image
                src={image}
                alt="Model Performance"
                objectFit="contain"
                maxWidth="100%"
                maxHeight={{ base: '300px', md: '550px' }} // Limit image height on smaller screens
              />
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    );
};

const ForecastCredits = () => {
    return (
        <Flex
            flexDir="column"
            background="gray.50"
            px={"1.5em"}
            py={"1em"}
            borderRadius="10px"
            justifyContent="center"
            width="100%"
        >
            <Text fontWeight="600" fontSize="14px" mb={"0.5em"}>Forecast Made Possible By</Text>
            <Flex flexDir="column" fontSize={"13px"}>
                <Link isExternal={true} href="https://www.geoboundaries.org">
                    <Text _hover={{ color: 'red.500', textDecorationColor: "red.500", textDecoration: "underline" }} fontWeight="500">GeoBoundaries</Text>
                </Link>
                <Link isExternal={true} href="https://acleddata.com">
                    <Text _hover={{ color: 'red.500', textDecorationColor: "red.500", textDecoration: "underline" }} fontWeight="500">ACLED</Text>
                </Link>
                <Link isExternal={true} href="https://www.worldpop.org">
                    <Text _hover={{ color: 'red.500', textDecorationColor: "red.500", textDecoration: "underline" }} fontWeight="500">WorldPop</Text>
                </Link>
                <Link isExternal={true} href="https://atmosphere.copernicus.eu">
                    <Text _hover={{ color: 'red.500', textDecorationColor: "red.500", textDecoration: "underline" }} fontWeight="500">Copernicus Climate Change Service</Text>
                </Link>
            </Flex>
        </Flex>
    );
}

export default Sidebar;
