import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Flex } from '@chakra-ui/react';
import LoginForm from './LoginForm';
import SignupForm from './SignupForm';

const Auth = ({ setSignupError, signupError, setLoginError, loginError, handleLogin, handleSignup }) => {

  const navigate = useNavigate();
  const [authState, setAuthState] = useState('login');
  const [signupSuccess, setSignupSuccess] = useState(false);

  const handleSwitchAuthState = () => {
    authState === "login" ? setAuthState("signup") : setAuthState("login");
    setSignupSuccess(false);
    setSignupError(false);
    setLoginError(false);
  }

  const handleSubmit = (user) => {
    const onLoginSuccess = () => navigate("/dashboard");
    const onSignupSuccess = () => setSignupSuccess(true);
    if (authState === "login") {
      handleLogin(user, onLoginSuccess);
    } else {
      handleSignup(user, onSignupSuccess);
    }
  }

  return (
    <Flex height="85vh" alignItems="center" justifyContent="center">
      {authState === "login" ?
        <LoginForm loginError={loginError} handleSwitchAuthState={handleSwitchAuthState} handleSubmit={handleSubmit} />
        :
        <SignupForm signupSuccess={signupSuccess} signupError={signupError} handleSwitchAuthState={handleSwitchAuthState} handleSubmit={handleSubmit} />

      }
    </Flex>
  );
};

export default Auth;