import React, { useEffect, useRef, useState } from 'react';
import { Box } from '@chakra-ui/react';

// Function to dynamically load Google Maps script
const loadGoogleMapsScript = (callback) => {
  const existingScript = document.getElementById('googleMaps');

  if (!existingScript) {
    const script = document.createElement('script');
    script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyCvbOfhe5SSsa5Br_r7YqzEfV89eUTWwxg&libraries=visualization,places';
    script.id = 'googleMaps';
    script.async = true;
    script.defer = true;

    document.body.appendChild(script);

    script.onload = () => {
      if (callback) callback();
    };
  } else if (callback) {
    callback();
  }
};

// Function to generate hexagonal coordinates
const getHexagonCoords = (center, radius) => {
  const angle = Math.PI / 3; // 60 degrees
  const coords = [];

  for (let i = 0; i < 6; i++) {
    const x = center.lng() + radius * Math.cos(angle * i);
    const y = center.lat() + radius * Math.sin(angle * i);
    coords.push({ lat: y, lng: x });
  }

  // Ensure the hexagon is closed by repeating the first coordinate
  coords.push(coords[0]);

  return coords;
};

function getColorForWeight(weight) {
  if (weight <= 0) {
    return 'rgba(255, 255, 255, 0)';  // Transparent
  } else if (weight <= 0.25) {
    // Interpolate between transparent white and solid white
    const whiteSolid = [255, 255, 255, 1];
    const whiteTransparent = [255, 255, 255, 0];
    const color = interpolateColor(whiteTransparent, whiteSolid, weight / 0.25);
    return `rgba(${color[0]}, ${color[1]}, ${color[2]}, ${color[3]})`;
  } else if (weight <= 0.50) {
    // Interpolate between solid white and light orange (rgba(255, 200, 0, 1))
    const lightOrange = [255, 200, 0, 1];
    const whiteSolid = [255, 255, 255, 1];
    const color = interpolateColor(whiteSolid, lightOrange, (weight - 0.25) / 0.25);
    return `rgba(${color[0]}, ${color[1]}, ${color[2]}, ${color[3]})`;
  } else if (weight <= 0.75) {
    // Interpolate between light orange and darker orange (rgba(255, 100, 0, 1))
    const darkOrange = [255, 100, 0, 1];
    const lightOrange = [255, 200, 0, 1];
    const color = interpolateColor(lightOrange, darkOrange, (weight - 0.50) / 0.25);
    return `rgba(${color[0]}, ${color[1]}, ${color[2]}, ${color[3]})`;
  } else {
    // Interpolate between darker orange and red (rgba(255, 0, 0, 1))
    const red = [255, 0, 0, 1];
    const darkOrange = [255, 100, 0, 1];
    const color = interpolateColor(darkOrange, red, (weight - 0.75) / 0.25);
    return `rgba(${color[0]}, ${color[1]}, ${color[2]}, ${color[3]})`;
  }
}

// Helper function to interpolate between two RGBA colors
function interpolateColor(color1, color2, factor) {
  const result = color1.map((c, i) => c + factor * (color2[i] - c));
  return result.map(Math.round);
}

const HeatMap = ({ heatMapData, day, showHeatMap, country }) => {
  const mapRef = useRef(null);
  const dataLayerRef = useRef(null);
  const [map, setMap] = useState(null);

  useEffect(() => {
    loadGoogleMapsScript(() => {
      if (window.google && window.google.maps) {
        const googleMap = new window.google.maps.Map(mapRef.current, {
          center: { lat: 20, lng: 10 }, // Default center
          zoom: 2, // Default zoom for global view
          fullscreenControl: true,
          zoomControl: false,
          streetViewControl: false,
          mapTypeControl: false,
        });
        setMap(googleMap);
      } else {
        console.error('Google Maps script did not load correctly.');
      }
    });
  }, []);

  // Use effect to handle zooming based on country change
  useEffect(() => {
    if (window.google && window.google.maps && map && country) {
      const geocodeCountry = async (countryName) => {
        const geocoder = new window.google.maps.Geocoder();
        geocoder.geocode({ address: countryName }, (results, status) => {
          if (status === window.google.maps.GeocoderStatus.OK && results[0]) {
            const { lat, lng } = results[0].geometry.location;
            map.setCenter({ lat: lat(), lng: lng() });
            map.setZoom(5);
          } else {
            console.error('Geocoding failed for country:', countryName);
          }
        });
      };

      if (country !== '') {
        geocodeCountry(country);
      } else {
        map.setCenter({ lat: 20, lng: 10 });
        map.setZoom(2); // Reset to default zoom if no country is provided
      }
    }
  }, [country, map]);

  // Use effect to handle hexagon data and day change
  useEffect(() => {
    if (window.google && window.google.maps && map) {
      const getHeatMapDataFromJSON = () => {
        const dates = Object.keys(heatMapData);
        const selectedDate = dates[day];
        const dataForDate = heatMapData[selectedDate];
        return dataForDate.map(point => ({
          location: new window.google.maps.LatLng(
            parseFloat(point['lat-long'][0]),
            parseFloat(point['lat-long'][1])
          ),
          weight: parseFloat(point.weight)
        }));
      };

      if (showHeatMap) {
        const data = getHeatMapDataFromJSON();

        if (!dataLayerRef.current) {
          const dataLayer = new window.google.maps.Data();
          dataLayer.setMap(map);
          dataLayerRef.current = dataLayer;
        }

        dataLayerRef.current.forEach(feature => dataLayerRef.current.remove(feature));

        const hexagonFeatures = data.map(point => {
          const coords = getHexagonCoords(point.location, 0.1);
          return {
            type: 'Feature',
            geometry: {
              type: 'Polygon',
              coordinates: [coords.map(coord => [coord.lng, coord.lat])]
            },
            properties: {
              weight: point.weight
            }
          };
        });

        dataLayerRef.current.addGeoJson({
          type: 'FeatureCollection',
          features: hexagonFeatures
        });

        dataLayerRef.current.setStyle(feature => {
          const weight = feature.getProperty('weight').toFixed(1);
          const color = getColorForWeight(weight);
          if (weight > 0) {
            return {
              strokeColor: 'lightgray',
              strokeWeight: 1,
              fillColor: color,
              fillOpacity: 0.7
            };
          } else {
            return {
              strokeColor: 'lightgray',
              strokeWeight: 1,
              fillColor: 'transparent',
              fillOpacity: 0
            };
          }
        });
      } else {
        if (dataLayerRef.current) {
          dataLayerRef.current.setMap(null);
        }
      }
    }
  }, [showHeatMap, map, heatMapData, day]);

  return (
    <Box 
      className="custom-map-container" 
      ref={mapRef} 
      height="100%"
      width="100%"
      sx={{ outline: 'none' }} 
    />
  );
};

export default HeatMap;