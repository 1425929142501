import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, Outlet, useLocation } from 'react-router-dom';
import axios from 'axios';
import { ChakraProvider, Box } from '@chakra-ui/react';
import './App.css';
import Dashboard from "./pages/Dashboard/Dashboard";
import About from "./pages/About";
import Mission from "./pages/Mission";
import Team from "./pages/Team";
import Navbar from './components/Navbar';
import Auth from './pages/Auth/Auth';
import Footer from './components/Footer';

const App = () => {
    const [authenticated, setAuthenticated] = useState(false);
    const [user, setUser] = useState();
    const [loginError, setLoginError] = useState(false);
    const [signupError, setSignupError] = useState(false);
    const [tokenCheckLoading, setTokenCheckLoading] = useState(true);
    const API_URL = "https://api.heliosdata.io";

    useEffect(() => {
        const token = localStorage.getItem('access_token');
        if (token) {
            axios.get(`${API_URL}/auth/verify-token`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
                .then(response => {
                    setUser(response.data.user);
                    setAuthenticated(true);
                    setTokenCheckLoading(false);
                })
                .catch(error => {
                    console.error('Token verification failed', error);
                    localStorage.removeItem('access_token');
                    setAuthenticated(false);
                    setTokenCheckLoading(false);
                });
        } else {
            setAuthenticated(false);
            setTokenCheckLoading(false);
        }
    }, []);

    const handleSignup = (user, onSuccess) => {
        axios.post(`${API_URL}/auth/signup`, {
            firstname: user.firstName,
            lastname: user.lastName,
            email: user.email,
            phone: user.phone,
            password: user.password,
            comments: user.comments
        })
            .then(response => {
                setSignupError(false);
                onSuccess();
            })
            .catch(error => {
                setSignupError(true);
                console.error('There was an error signing up!', error);
            });
    };

    const handleLogin = (user, onSuccess) => {
        axios.put(`${API_URL}/auth/login`, {
            email: user.email,
            password: user.password
        })
            .then(response => {
                localStorage.setItem('access_token', response.data.access_token);
                setUser(response.data.user);
                setAuthenticated(true);
                setLoginError(false);
                onSuccess();
            })
            .catch(error => {
                setLoginError(true);
                console.error('There was an error logging in!', error);
            });
    };

    const handleLogout = () => {
        axios.post(`${API_URL}/auth/logout`, null, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('access_token')}`
            }
        })
            .then(response => {
                setAuthenticated(false);
                localStorage.removeItem('access_token');
            })
            .catch(error => {
                console.error('Error logging out:', error);
            });
    };

    const ProtectedRoute = () => {
        const location = useLocation();
    
        if (tokenCheckLoading) {
            return <div></div>;
        }
    
        if (!authenticated) {
            return <Navigate replace={true} to="/auth" state={{ from: location }} />;
        }
    
        return <Outlet />;
    };
    

    const FooterDisplay = () => {
        const location = useLocation();
        return (location.pathname !== '/dashboard' && location.pathname !== '/auth') ? <Footer /> : null;
    }

    return (
        <ChakraProvider>
            <Router>
                <Navbar handleLogout={handleLogout} authenticated={authenticated} />
                <Box bg="gray.100">
                    <Routes>
                        <Route path="/" element={<Navigate replace={true} to={authenticated ? "/dashboard" : "/auth"} />} />
                        <Route path="/auth" element={
                            authenticated ? <Navigate replace={true} to="/dashboard" /> :
                                <Auth
                                    setSignupError={setSignupError}
                                    signupError={signupError}
                                    setLoginError={setLoginError}
                                    loginError={loginError}
                                    handleSignup={handleSignup}
                                    handleLogin={handleLogin}
                                />
                        } />
                        <Route element={<ProtectedRoute />}>
                            <Route path="/dashboard" element={<Dashboard user={user} />} />
                        </Route>
                        <Route path="/about" element={<About />} />
                        <Route path="/team" element={<Team />} />
                        <Route path="/mission" element={<Mission />} />
                    </Routes>
                </Box>
                <FooterDisplay />
            </Router>
        </ChakraProvider>
    );
}

export default App;