import React, { useState } from 'react';
import { 
    Flex, Image, IconButton, Text, useBreakpointValue, Drawer, DrawerOverlay, DrawerContent, DrawerCloseButton, DrawerHeader, DrawerBody, useDisclosure 
} from '@chakra-ui/react';
import { MdLogout } from "react-icons/md";
import { Link as RouterLink } from 'react-router-dom';
import { FaBars } from 'react-icons/fa';

const Navbar = ({ authenticated, handleLogout }) => {
    const { isOpen, onOpen, onClose } = useDisclosure(); // Chakra UI hook for drawer
    const [placement] = useState('left'); // Set placement for the drawer

    // Responsive font sizes, logo width, and icon sizes
    const fontSize = useBreakpointValue({ base: '0.7rem', sm: '0.75rem', md: '0.875rem', lg: '1rem' });
    const logoWidth = useBreakpointValue({ base: '55%', sm: '55%', md: '60%', lg: '65%' });
    const logoutIcon = useBreakpointValue({ base: '1.1em', sm: '1.25em', md: '1.5em', lg: '1.75em' });
    const paddingX = useBreakpointValue({ base: '1em', sm: '1.5em', md: '2em' });
    const spacing = useBreakpointValue({ base: '1em', sm: '1.5em', md: '2em' });
    const showHamburgerMenu = useBreakpointValue({ base: true, md: false }); // Show hamburger menu only on mobile

    return (
        <Flex 
            px={paddingX} 
            minHeight="15vh" 
            backgroundColor="white"
            borderBottomWidth="0.5px"
            borderBottomColor="gray.400"
            backdropFilter="blur(10px)"
            alignItems="center"
            justifyContent="space-between"
        >

            {/* Centered Logo */}
            <Flex flex="1" justifyContent={showHamburgerMenu ? 'center' : 'flex-start'}>
                <RouterLink to={authenticated ? "/dashboard" : "/auth"}>
                    <Image 
                        src="Helios.png" 
                        alt="Helios Logo" 
                        maxWidth={logoWidth}  // Responsive logo width
                        height="auto" 
                    />
                </RouterLink>
            </Flex>

            {/* Right Side - Hamburger Menu (visible only on mobile) */}
            {showHamburgerMenu && (
                <IconButton
                    icon={<FaBars />}
                    aria-label="Open menu"
                    variant="ghost"
                    onClick={onOpen}
                    fontSize="1.25rem"
                />
            )}

            {/* Right Side - Navigation Links, LinkedIn, and Logout (hidden on mobile) */}
            {!showHamburgerMenu && (
                <Flex alignItems="center">
                    {authenticated && (
                        <RouterLink to="/dashboard">
                            <Text
                                mr={spacing}  // Responsive spacing between links
                                fontSize={fontSize}  // Responsive font size
                                fontWeight="500"
                                color="black" 
                                cursor="pointer" 
                                whiteSpace="nowrap"  // Prevent line breaks
                                _hover={{ background: "none", color: "red" }}
                                transition="color 0.2s ease"
                            >
                                Dashboard
                            </Text>
                        </RouterLink>
                    )}
                    <RouterLink to="/mission">
                        <Text
                            mr={spacing}
                            fontSize={fontSize}
                            fontWeight="500"
                            color="black"
                            cursor="pointer"
                            whiteSpace="nowrap"
                            _hover={{ background: "none", color: "red" }}
                            transition="color 0.2s ease"
                        >
                            Mission
                        </Text>
                    </RouterLink>
                    <RouterLink to="/about">
                        <Text
                            mr={spacing}
                            fontSize={fontSize}
                            fontWeight="500"
                            color="black"
                            cursor="pointer"
                            whiteSpace="nowrap"
                            _hover={{ background: "none", color: "red" }}
                            transition="color 0.2s ease"
                        >
                            About Us
                        </Text>
                    </RouterLink>
                    <RouterLink to="/team">
                        <Text
                            // mr={spacing}
                            fontSize={fontSize}
                            fontWeight="500"
                            color="black"
                            cursor="pointer"
                            whiteSpace="nowrap"
                            _hover={{ background: "none", color: "red" }}
                            transition="color 0.2s ease"
                        >
                            Our Team
                        </Text>
                    </RouterLink>

                    {/* Logout Icon */}
                    {authenticated && (
                        <IconButton
                            onClick={handleLogout}
                            aria-label="Logout"
                            icon={<MdLogout size={logoutIcon} />}
                            variant="ghost"
                            _hover={{ background: "none", color: "red" }}
                            color="black"
                            ml={spacing}
                        />
                    )}
                </Flex>
            )}

            {/* Drawer for mobile navigation */}
            <Drawer placement={placement} onClose={onClose} isOpen={isOpen}>
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerCloseButton />
                    <DrawerHeader></DrawerHeader>
                    <DrawerBody>
                        <Flex direction="column">
                            {authenticated && (
                                <RouterLink to="/dashboard" onClick={onClose}>
                                    <Text
                                        mb={spacing}
                                        fontSize="1.25rem"
                                        fontWeight="500"
                                        color="black"
                                        cursor="pointer"
                                        _hover={{ color: "red" }}
                                    >
                                        Dashboard
                                    </Text>
                                </RouterLink>
                            )}
                            <RouterLink to="/mission" onClick={onClose}>
                                <Text
                                    mb={spacing}
                                    fontSize="1.25rem"
                                    fontWeight="500"
                                    color="black"
                                    cursor="pointer"
                                    _hover={{ color: "red" }}
                                >
                                    Mission
                                </Text>
                            </RouterLink>
                            <RouterLink to="/about" onClick={onClose}>
                                <Text
                                    mb={spacing}
                                    fontSize="1.25rem"
                                    fontWeight="500"
                                    color="black"
                                    cursor="pointer"
                                    _hover={{ color: "red" }}
                                >
                                    About Us
                                </Text>
                            </RouterLink>
                            <RouterLink to="/team" onClick={onClose}>
                                <Text
                                    mb={spacing}
                                    fontSize="1.25rem"
                                    fontWeight="500"
                                    color="black"
                                    cursor="pointer"
                                    _hover={{ color: "red" }}
                                >
                                    Our Team
                                </Text>
                            </RouterLink>

                            {/* Add LinkedIn and Logout icons inside the drawer */}
                            <Flex flexDir="column" justifyContent="space-between">
                                {authenticated && (
                                    <Text
                                        mb={spacing}
                                        fontSize="1.25rem"
                                        fontWeight="500"
                                        color="black"
                                        cursor="pointer"
                                        _hover={{ color: "red" }}
                                        onClick={handleLogout}
                                    >
                                        Log Out
                                    </Text>
                                )}
                            </Flex>
                        </Flex>
                    </DrawerBody>
                </DrawerContent>
            </Drawer>
        </Flex>
    );
};

export default Navbar;