import React, { useState, useEffect } from 'react';
import { Flex, Box, Button, useBreakpointValue } from '@chakra-ui/react';
import axios from 'axios';
import HeatMap from './HeatMap';
import Sidebar from './Sidebar';

const Dashboard = ({ user }) => {
  const [country, setCountry] = useState('');
  const [showHeatMap, setShowHeatMap] = useState(false);
  const [day, setDay] = useState();
  const [forecastData, setForecastData] = useState();
  const [heatMapData, setHeatMapData] = useState();
  const [actors, setActors] = useState([]);
  const [date, setDate] = useState();
  const [actor, setActor] = useState("");
  const [loading, setLoading] = useState(false);
  const [actorsLoading, setActorsLoading] = useState(false);
  const [showSidebar, setShowSidebar] = useState(false);
  const API_URL = "https://api.heliosdata.io";
  const countries = user.countries;

  // Updated breakpoint value to consider tablet views (base, sm, md, lg, xl)
  const isDesktopOrLarger = useBreakpointValue({ base: false, md: false, lg: true, xl: true });
  const isTabletOrSmaller = useBreakpointValue({ base: true, sm: true, md: true, lg: false });

  const token = localStorage.getItem('access_token');

  useEffect(() => {
    const token = localStorage.getItem('access_token');
    axios.get(`${API_URL}/forecast/most-recent-week`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        setDate(response.data.most_recent_date);
      })
      .catch((error) => {
        console.error('Error fetching most recent week:', error);
      });
  }, []);

  useEffect(() => {
    if (date) {
      const currentDate = new Date();
      const dayOfWeek = currentDate.getDay(); // 0 is Sunday, 1 is Monday, etc.
      const dayMapping = {
        3: 0,  // Wednesday
        4: 1,  // Thursday
        5: 2,  // Friday
        6: 3,  // Saturday
        0: 4,  // Sunday
        1: 5,  // Monday
        2: 6   // Tuesday
      };
      setDay(dayMapping[dayOfWeek]);
    }
  }, [date]);

  const handleChangeCountry = (countryVal) => {
    if (countryVal !== '') {
      setCountry(countryVal);
      setActorsLoading(true);
      axios.get(`${API_URL}/actors/${countryVal}/${date}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => {
          setActors(response.data.actors);
          setDate(response.data.date);
          setActorsLoading(false);
        })
        .catch((error) => {
          console.error('Error fetching actors:', error);
        });
    }
  }

  const handleSubmit = () => {
    if (country !== '' && actor !== '') {
      setLoading(true);
      const date_country_actor = `${date}_${country}_${actor}`;
      axios.get(`${API_URL}/forecast/${date_country_actor}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => {
          const limitedHeatMapData = Object.fromEntries(
            Object.entries((response.data)).slice(5,12)
          );
          setHeatMapData(limitedHeatMapData);
          setForecastData(response.data);
          setShowHeatMap(true);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          console.error('Error fetching forecast data:', error);
        });
    }
  };  

  const handleToggleSidebar = () => {
    setShowSidebar((prev) => !prev);
  };

  return (
    <Flex flexDir="row" ml={isDesktopOrLarger ? 4 : 0} height="85vh">
      {/* Sidebar for Desktop (Laptop or larger) */}
      {isDesktopOrLarger && (
        <Box width="30vw" overflowY="auto">
          <Sidebar
            countries={countries}
            country={country}
            handleChangeCountry={handleChangeCountry}
            date={date}
            day={day}
            setDay={setDay}
            handleSubmit={handleSubmit}
            forecastData={forecastData}
            actors={actors}
            actor={actor}
            setActor={setActor}
            loading={loading}
            actorsLoading={actorsLoading}
          />
        </Box>
      )}

      {/* Main Content Area (HeatMap) */}
      <Flex
        overflowY="-moz-hidden-unscrollable"
        width={isTabletOrSmaller && !showSidebar ? "100vw" : "70vw"}
        position="relative"
        justifyContent={isTabletOrSmaller && !showSidebar ? "flex-start" : "flex-start"}
      >
        {/* "Show Menu" button for Mobile and Tablet */}
        {isTabletOrSmaller && !showSidebar && (
          <Button
            position="absolute"
            top="10px"
            left="10px"
            zIndex="10"
            colorScheme="red"
            onClick={handleToggleSidebar}
          >
            Show Menu
          </Button>
        )}
        
        <HeatMap
          heatMapData={heatMapData}
          day={day}
          showHeatMap={showHeatMap}
          country={country}
        />
      </Flex>

      {/* Sidebar for Tablet and Mobile */}
      {isTabletOrSmaller && showSidebar && (
        <Box width="100%" position="absolute" top="0" left="0" zIndex="5" bg="white" boxShadow="md" height="100%">
          {/* Close Menu button on top of the sidebar */}
          <Button
            position="absolute"
            top="10px"
            right="10px"
            zIndex="10"
            colorScheme="red"
            onClick={handleToggleSidebar}
          >
            Close Menu
          </Button>

          <Sidebar
            countries={countries}
            country={country}
            handleChangeCountry={handleChangeCountry}
            date={date}
            day={day}
            setDay={setDay}
            handleSubmit={handleSubmit}
            forecastData={forecastData}
            actors={actors}
            actor={actor}
            setActor={setActor}
            loading={loading}
          />
        </Box>
      )}
    </Flex>
  );
};

export default Dashboard;