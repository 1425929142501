import { Grid, Text, Button, Box, Flex, Image, useBreakpointValue } from '@chakra-ui/react';

const Footer = () => {
    const logoSize = useBreakpointValue({ base: '40%', sm: '50%', md: '60%', lg: '70%' });
    // const mapSize = useBreakpointValue({ base: '300px', sm: '320px', md: '350px', lg: '400px' });

    return (
        <Flex
            backgroundColor="white"
            minHeight="30vh"  // Set the height to 30% of the viewport
            justifyContent="center"
            alignItems="center"  // Vertically center the content
            padding={{ base: '1em', sm: '2em', md: '3em' }}  // Add padding for all screen sizes
        >
            <Grid 
                templateColumns={{ base: '1fr', md: 'repeat(3, 1fr)' }}  // Stacks vertically for smaller screens, and 3 columns for larger screens
                gap={6}  // Increased gap for better spacing on larger screens
                alignItems="center"
                textAlign="center"
                width="100%"
            >
                {/* Left Column: Logo */}
                <Box display="flex" flexDirection="column" alignItems="center" mb={{ base: '2em', sm: '0' }}>
                    <Image src="Helios.png" alt="Helios Logo" width={logoSize} height="auto" />
                </Box>
                
                {/* Middle Column: Contact Us Button */}
                <Box display="flex" flexDirection="column" alignItems="center" mb={{ base: '2em', sm: '0' }}>
                    <Text fontSize={{ base: '14px', sm: '16px', md: '18px' }} fontWeight="400" mb={2}>
                        We'd love to work with you.
                    </Text>
                    <a
                        rel="noreferrer"
                        target="_blank"
                        href="https://calendly.com/alexander-casendino-heliosdata/30min"
                    >
                        <Button
                            cursor="pointer"
                            bg="red.400"
                            color="white"
                            _hover={{ bg: 'red.500' }}
                            borderRadius="md"
                            w={{ base: '100%', sm: '125px', md: '200px' }}  // Responsive width for the button
                            fontSize={{ base: '12px', sm: '14px', md: '16px' }}  // Responsive font size
                            fontWeight="bold"
                        >
                            Contact Us
                        </Button>
                    </a>
                </Box>

                {/* Right Column: Office Map */}
                <Box display="flex" flexDirection="column" alignItems="center" mb={{ base: '2em', sm: '0' }}>
                    <Text fontSize={{ base: '14px', sm: '16px', md: '18px' }} fontWeight="400" color="black" mb={2}>
                        Based in Washington, D.C.
                    </Text>
                    {/* <Image
                        width={mapSize}
                        height="auto"
                        src="OfficeMap.jpeg"
                        alt="Office Map"
                    /> */}
                </Box>
            </Grid>
        </Flex>
    );
};

export default Footer;