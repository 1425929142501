import React, { useState } from 'react';
import {
    Flex,
    Text,
    Button,
    Input,
    FormControl,
    Heading,
    Divider,
    Image,
    useBreakpointValue,
    Alert,
    AlertIcon
} from '@chakra-ui/react';

const SignupForm = ({ signupSuccess, signupError, handleSubmit, handleSwitchAuthState }) => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [password, setPassword] = useState('');
    const [comments, setComments] = useState('');

    const isMobile = useBreakpointValue({ base: true, md: false });

    const clearForm = () => {
        setFirstName('');
        setLastName('');
        setEmail('');
        setPhone('');
        setPassword('');
        setComments('');
    }
    
    const onSubmit = (e) => {
        e.preventDefault();
        const user = {
            firstName,
            lastName,
            email,
            phone,
            password,
            comments
        };
        handleSubmit(user);
        clearForm();
    };

    const imageHeight = useBreakpointValue({ base: '75px', md: '100px', lg: '125px' });

    return (
        <Flex 
            height={{ base: 'auto', md: '77.5%', lg: '87.5%' }} 
            backgroundColor="gray.100" 
            mx={{ base: "1em", md: "6em" }} 
            px={{ base: "1em", md: "2em" }} 
        >
            <Flex
                direction={{ base: 'column', md: 'row' }}
                bg="white"
                rounded="xl"
                boxShadow="lg"
                alignItems="center"
                width="100%"
                pb={isMobile && "2em"}
            >
                <Flex
                    width={{ base: '100%', md: '50%' }}
                    borderLeftRadius={{ base: 'none', md: 'lg' }}
                    alignItems="center"
                    textAlign="center"
                    flexDir="column"
                    px={{ base: '1em', md: '2em' }} 
                    mt={isMobile && "1.5em"}
                    mb={isMobile && "2em"}
                >
                    <Image src="Helios.png" alt="Helios Logo" height={imageHeight} />
                    <Text 
                        mt={{ base: "0em", md: "1em" }} 
                        color="grey" 
                        fontSize={{ base: '13px', md: '15px', lg: '17px' }} 
                        fontWeight="300"
                    >
                        A machine learning platform to detect political violence and protect your team.
                        Sign up to gain access to the dashboard.
                    </Text>
                </Flex>

                {isMobile ? (
                    <Divider orientation="horizontal" display="block" />
                ) : (
                    <Divider orientation="vertical" display="block" />
                )}

                <Flex 
                    width={{ base: '100%', md: '50%' }} 
                    justifyContent="center"
                    px={{ base: '1em', md: '0' }} 
                >
                    <form onSubmit={onSubmit} style={{ width: '100%' }}>
                        <Flex flexDirection="column" alignItems="center" width="100%">
                            <Heading 
                                fontSize={{ base: '30px', md: '36px', lg: '40px' }} 
                                fontWeight="600" 
                                color="red.500"
                                mt={isMobile && '0.75em'} 
                                mb={"0.5em"}
                            >
                                Sign Up
                            </Heading>

                            {signupError && (
                                <Alert
                                    status="error"
                                    mb={"1em"}
                                    borderRadius={5}
                                    w={{ base: '250px', md: '300px', lg: '400px' }}
                                >
                                    <AlertIcon
                                        mr="1.5"
                                        w={{ base: '10px', md: '12px', lg: '14px' }}
                                        h={{ base: '10px', md: '12px', lg: '14px' }}
                                    />
                                    <Text fontWeight="400" fontSize={{ base: '10px', md: '12px', lg: '14px' }}>
                                        An account already exists with this email.
                                    </Text>
                                </Alert>
                            )}

                            {signupSuccess && (
                                <Alert
                                    status="success"
                                    mb={"1em"}
                                    borderRadius={5}
                                    w={{ base: '250px', md: '300px', lg: '400px' }}
                                >
                                    <AlertIcon
                                        mr="3"
                                        w={{ base: '10px', md: '12px', lg: '14px' }}
                                        h={{ base: '10px', md: '12px', lg: '14px' }}
                                    />
                                    <Text fontWeight="400" fontSize={{ base: '10px', md: '12px', lg: '14px' }}>
                                        Account requested! A member of our team will reach out to you to finalize your account.
                                    </Text>
                                </Alert>
                            )}

                            <Flex flexDir="column" justifyContent="center">
                                <Flex mb={isMobile ? "0.75em" : "1em"} justifyContent="space-between" width={{ base: '250px', md: '300px', lg: '400px' }}>
                                    <FormControl isRequired width="49%">
                                        <Input
                                            value={firstName}
                                            onChange={(e) => setFirstName(e.target.value)}
                                            placeholder="First Name"
                                            bg="gray.200"
                                            type="text"
                                            fontSize={{ base: '10px', md: '12px', lg: '14px' }}
                                            h={{ base: '35px', md: '40px', lg: '45px' }} 
                                            pl={"1em"}
                                            borderRadius="5px"
                                        />
                                    </FormControl>
                                    <FormControl isRequired width="49%">
                                        <Input
                                            value={lastName}
                                            onChange={(e) => setLastName(e.target.value)}
                                            placeholder="Last Name"
                                            bg="gray.200"
                                            type="text"
                                            fontSize={{ base: '10px', md: '12px', lg: '14px' }}
                                            h={{ base: '35px', md: '40px', lg: '45px' }} 
                                            pl={"1em"}
                                            borderRadius="5px"
                                        />
                                    </FormControl>
                                </Flex>
                                <FormControl isRequired mb={isMobile ? "0.75em" : "1em"}>
                                    <Input
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        placeholder="Email"
                                        bg="gray.200"
                                        type="email"
                                        fontSize={{ base: '10px', md: '12px', lg: '14px' }}
                                        h={{ base: '35px', md: '40px', lg: '45px' }} 
                                        w={{ base: '250px', md: '300px', lg: '400px' }}
                                        pl={"1em"}
                                        borderRadius="5px"
                                    />
                                </FormControl>
                                <FormControl isRequired mb={isMobile ? "0.75em" : "1em"}>
                                    <Input
                                        value={phone}
                                        onChange={(e) => setPhone(e.target.value)}
                                        placeholder="Phone Number"
                                        bg="gray.200"
                                        fontSize={{ base: '10px', md: '12px', lg: '14px' }}
                                        h={{ base: '35px', md: '40px', lg: '45px' }} 
                                        w={{ base: '250px', md: '300px', lg: '400px' }}
                                        pl={"1em"}
                                        borderRadius="5px"
                                    />
                                </FormControl>
                                <FormControl isRequired mb={isMobile ? "0.75em" : "1em"}>
                                    <Input
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        placeholder="Password"
                                        type="password"
                                        bg="gray.200"
                                        fontSize={{ base: '10px', md: '12px', lg: '14px' }}
                                        h={{ base: '35px', md: '40px', lg: '45px' }} 
                                        w={{ base: '250px', md: '300px', lg: '400px' }}
                                        pl={"1em"}
                                        borderRadius="5px"
                                    />
                                </FormControl>
                                <FormControl mb={isMobile ? "0.75em" : "1em"}>
                                    <Input
                                        value={comments}
                                        onChange={(e) => setComments(e.target.value)}
                                        placeholder="Additional Comments (optional)"
                                        bg="gray.200"
                                        type="text"
                                        fontSize={{ base: '10px', md: '12px', lg: '14px' }}
                                        h={{ base: '35px', md: '40px', lg: '45px' }}
                                        w={{ base: '250px', md: '300px', lg: '400px' }}
                                        pl={"1em"}
                                        borderRadius="5px"
                                    />
                                </FormControl>
                            </Flex>
                            <Button
                                cursor="pointer"
                                type="submit"
                                bg="red.400"
                                color="white"
                                _hover={{ bg: 'red.600' }}
                                borderRadius="5px"
                                h={{ base: '30px', md: '35px', lg: '40px' }}
                                w={{ base: '250px', md: '300px', lg: '400px' }}
                                fontSize={{ base: '10px', md: '12px', lg: '14px' }}
                                fontWeight="bold"
                                isDisabled={!email || !password || !firstName || !lastName || !phone}
                                mb={isMobile ? "1em" : "1.25em"}
                            >
                                Request Account
                            </Button>
                            <Flex justifyContent="center" flexDir="row">
                                <Text mr={1} fontSize={{ base: '10px', md: '12px', lg: '14px' }} color="gray.600">
                                    Already have an account?
                                </Text>
                                <Text
                                    _hover={{ color: 'red.600' }}
                                    onClick={handleSwitchAuthState}
                                    fontSize={{ base: '10px', md: '12px', lg: '14px' }}
                                    fontWeight="bold"
                                    color="red.400"
                                    cursor="pointer"
                                >
                                    Sign In Here!
                                </Text>
                            </Flex>
                        </Flex>
                    </form>
                </Flex>
            </Flex>
        </Flex>
    );
};

export default SignupForm;